import React from 'react';
import { Box, Typography, Grid, Card, CardMedia, CardContent } from '@mui/material';

function Projects() {
  return (
    <Box id="projects" sx={{ padding: '40px 0' }}>
      <Typography variant="h3" gutterBottom>
        Projects
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardMedia
              component="img"
              image="/images/LyricJamSonicMode.jpg"
              alt="Project 1"
              sx={{
                height: '250px', // Set the height of the image
                width: '100%', // Ensure it covers the width of the Card
                objectFit: 'cover', // Maintain aspect ratio and cover the area
                opacity: 0.9, // Set the opacity to 80%
              }}
            />
            <CardContent>
              <Typography variant="body1">LyricJam Sonic</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardMedia
              component="img"
              image="/images/LyricJamMode.jpg"
              alt="Project 2"
              sx={{
                height: '250px', // Set the height of the image
                width: '100%', // Ensure it covers the width of the Card
                objectFit: 'cover', // Maintain aspect ratio and cover the area
                opacity: 0.9, // Set the opacity to 80%
              }}
            />
            <CardContent>
              <Typography variant="body1">LyricJam</Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* Add more projects as needed */}
      </Grid>
    </Box>
  );
}

export default Projects;
