import React from 'react';
import { Box, Typography, Container, Link } from '@mui/material';

function Contact() {
  return (
    <Box id="contact" sx={{ padding: '40px 0' }}>
      <Container maxWidth="md">
        <Typography variant="h3" gutterBottom>
          Contact Information
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
          Olga Vechtomova
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
          Professor
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
          Department of Management Science and Engineering, Faculty of Engineering
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
          Cheriton School of Computer Science (cross-appointment)
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
          University of Waterloo
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
          200 University Avenue West
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
          Waterloo, Ontario, Canada, N2L 3G1
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
          Email: <Link href="mailto:ovechtom@uwaterloo.ca">ovechtom@uwaterloo.ca</Link>
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
          Phone: +1 519 888 4567 ext. 32675
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '4px', fontSize: '1.2rem' }}>
          Room: CPH 3631
        </Typography>
      </Container>
    </Box>
  );
}

export default Contact;
