import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, Link } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material'; // Import the media query hooks
import newsArticlesData from './newsArticles.json'; // Import the JSON file

function News() {
  const [newsArticles, setNewsArticles] = useState([]);

  // Use MUI's theme and media query hook to detect screen size
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    // Load the news articles from the JSON file
    setNewsArticles(newsArticlesData);
  }, []);

  return (
    <Container sx={{ padding: '40px 0' }}>
      <Typography variant="h3" gutterBottom>
        News
      </Typography>
      <Grid container spacing={4}>
        {/* Topmost news item */}
        <Grid item xs={12}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
            {/* Image */}
            <Box sx={{ width: isMobile ? '100%' : '40%', position: 'relative', overflow: 'hidden' }}>
              <img
                src="/images/LyricJamSonic_illustration.jpg"
                alt="Top news"
                style={{ width: '100%', height: isMobile ? 'auto' : '100%', objectFit: isMobile ? 'cover' : 'contain' }}
              />
            </Box>

            {/* Text Content */}
            <CardContent sx={{ width: isMobile ? '100%' : '60%' }}>
              <Typography variant="h5" component="div" gutterBottom>
                {newsArticles[0]?.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {newsArticles[0]?.date}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                {newsArticles[0]?.description}
              </Typography>
              {newsArticles[0]?.link && (
                <Typography variant="body2">
                  <Link href={newsArticles[0].link} target="_blank" rel="noopener">
                    Read more
                  </Link>
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Other news items */}
        {newsArticles.slice(1).map((article, index) => (
          <Grid item xs={12} md={6} key={index + 1}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h5" component="div" gutterBottom>
                  {article.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {article.date}
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                  {article.description}
                </Typography>
                {article.link && (
                  <Typography variant="body2">
                    <Link href={article.link} target="_blank" rel="noopener">
                      Read more
                    </Link>
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default News;
