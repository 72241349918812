import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';
import publications from './publications.json';  // Adjust the path based on your project structure

function SelectedPublications() {
  return (
    <Container sx={{ padding: '40px 0' }}>
      <Typography variant="h3" gutterBottom>
        Selected Publications
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '20px' }}>
        To see the full list of publications, please visit my Google Scholar profile: {' '}
        <Link href="https://scholar.google.ca/citations?user=Ln1-_JIAAAAJ&hl=en" target="_blank" rel="noopener">
          Google Scholar
        </Link>.
      </Typography>
      {publications.map((yearBlock, index) => (
        <Box key={index} sx={{ marginBottom: '30px' }}>
          {yearBlock.entries.map((entry, idx) => (
            <Box key={idx} sx={{ marginBottom: '20px' }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {entry.title}
              </Typography>
              <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                {entry.authors}
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: '10px' }}>
                {entry.details}
              </Typography>
              {entry.link && (
                <Link href={entry.link} target="_blank" rel="noopener">
                  [PDF]
                </Link>
              )}
            </Box>
          ))}
        </Box>
      ))}
    </Container>
  );
}

export default SelectedPublications;
