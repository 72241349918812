import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Header from './Header';
import MainPage from './MainPage';
import Overview from './Overview';
import Projects from './Projects';
import Publications from './Publications';
import Footer from './Footer';
import Contact from './Contact';
import News from './News';

function App() {
  return (
    <div className="app-container">
      <Router>
      <CssBaseline />
      <Header />
      <Container maxWidth="lg" className="content-container">
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/news" element={<News />} />
          <Route path="/NLP_lab" element={<Overview />} />
          <Route path="/NLP_lab.html" element={<Overview />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/publications" element={<Publications />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Container>
      <Footer />
    </Router>
    </div>
  );
}

export default App;
