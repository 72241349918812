import React from 'react';
import { Box, Typography, Container, Grid, Card, CardMedia, CardContent, Link } from '@mui/material';

function MainPage() {
  return (
    <>
      {/* Introduction Section */}
      <Container sx={{ padding: '40px 0' }}>
        {/* Your Name in Larger Font */}
        <Typography variant="h2" sx={{ textAlign: 'left', marginBottom: '24px' }}>
          Olga Vechtomova
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '1.2rem', textAlign: 'left' }}>
          Olga Vechtomova is a Professor at the University of Waterloo, specializing in Natural Language Processing (NLP), Artificial Intelligence (AI), and Creativity. Her research focuses on designing AI-based systems that enhance human creativity in various domains, including music and art.
        </Typography>
      </Container>

      {/* Featured Projects Section */}
      <Container sx={{ padding: '40px 0' }}>
        <Typography variant="h4" gutterBottom sx={{ textAlign: 'left' }}>
          Featured Projects
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Card sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image="/images/LyricJamSonicMode.jpg"
                alt="LyricJam Sonic"
                sx={{ height: '250px', width: '100%', objectFit: 'cover', opacity: 0.9 }}
              />
              <CardContent>
                <Typography variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                  LyricJam Sonic
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'left', marginTop: '10px' }}>
                  LyricJam Sonic is a generative system that creates evolving soundscapes in real-time to complement live music performances. It uses AI to analyze the music and generate soundscapes that adapt to the mood and dynamics of the performance.
                </Typography>
                <Box sx={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
                  <Link href="https://arxiv.org/pdf/2210.15638" target="_blank" rel="noopener">
                    PDF
                  </Link>
                  <Link href="https://lyricjam.ai" target="_blank" rel="noopener">
                    Web App
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                image="/images/LyricJamMode.jpg"
                alt="LyricJam"
                sx={{ height: '250px', width: '100%', objectFit: 'cover', opacity: 0.9 }}
              />
              <CardContent>
                <Typography variant="body1" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                  LyricJam
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'left', marginTop: '10px' }}>
                  LyricJam is an AI-driven system that generates lyrics in real-time during live instrumental performances. It listens to the music and creates lyrics that match the rhythm, melody, and emotional tone of the performance.
                </Typography>
                <Box sx={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
                  <Link href="https://arxiv.org/pdf/2106.01960" target="_blank" rel="noopener">
                    PDF
                  </Link>
                  <Link href="https://lyricjam.ai" target="_blank" rel="noopener">
                    Web App
                  </Link>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {/* Add more projects as needed */}
        </Grid>
      </Container>
    </>
  );
}

export default MainPage;
