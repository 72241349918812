import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box className="footer">
      <Typography variant="body2">
        Contact: ovechtom@uwaterloo.ca
      </Typography>
      <Typography variant="body2">
        &copy; 2024 Olga Vechtomova
      </Typography>
    </Box>
  );
}

export default Footer;
