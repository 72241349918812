import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // Import default React styles if present
import './styles.css'; // Import your custom styles
import App from './components/App';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const materialDesignShadow = '0px 2px 4px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.1)';

const theme = createTheme({
  typography: {
    fontFamily: 'Jost, sans-serif',
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none', // Remove underline from links
          color: '#A0522A', // Set your preferred link color
          '&:hover': {
            color: '#A0522A', // Optional: Different color on hover
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: materialDesignShadow, // Standard Material Design shadow
          borderRadius: '4px', // Material Design cards typically have a 4px border radius
          elevation: 1, // Standard elevation for a resting card
          // Material Design specifies that cards should have a standard background color
          backgroundColor: '#FFFFFF', 
        },
      },
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);

